<template>
  <div class="loader-item-queue">
    <Skeleton class="blur-skeleton" width="100%" height="90px" border-radius="10px" v-for="x in 3" />
  </div>
</template>

<style lang="scss" scoped>
.loader-item-queue {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  .blur-skeleton {
    background-color: #fafafa;
    box-shadow: 0px 8px 24px 0px rgba(176, 190, 197, 0.08), 0px 3px 5px 0px rgba(176, 190, 197, 0.30);
    backdrop-filter: blur(4px);
  }
}
</style>