<template>
  <div class="no-data-item-queue">
    Nenhum registro encontrado.
  </div>
</template>

<style lang="scss" scoped>
.no-data-item-queue {
  padding: 20px 0;
  text-align: center !important;
  box-shadow: 0px 8px 24px 0px rgba(176, 190, 197, 0.25), 0px 3px 5px 0px rgba(176, 190, 197, 0.30);
  backdrop-filter: blur(4px);
  border-radius: 8px;

  font-family: 'Roboto';

  color: var(--Neutral-Strong, #57595F);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
</style>